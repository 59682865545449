import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Baufinanzierungsberater = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="An 5 Fragen den idealen Baufinanzierungsberater erkennen" showCalc={false} />
            <Article>
                <p>
                    Bei der Entscheidung für eine{" "}
                    <Link to="/artikel/baufinanzierung/" target="_blank" rel="noreferrer noopener">
                        Baufinanzierung
                    </Link>{" "}
                    geht es oft um mehrere hunderttausend Euro, die du vermutlich jahrzehntelang zurückzahlen werden
                    musst. Außerdem wird sie meistens nur einmal im Leben getroffen und das heißt, dass du meistens
                    nicht viel Erfahrung mitbringst. Bei einer derart schwerwiegende Entscheidung solltest du aber gut
                    informiert sein und dir vorher unbedingt Ratschläge und Tipps einholen. Wenn du bereits weißt, dass
                    du eine Baufinanzierung brauchst, aber noch unschlüssig bist, wie du auch den besten
                    Baufinanzierungsberater für deine Finanzierung findest, bist du hier richtig. In diesem Beitrag
                    möchten wir dir anhand von 5 Fragen dabei helfen, herauszufinden was den idealen Partner für deine
                    Baufinanzierung ausmacht.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Kreditvergleich anfordern!
                    </a>
                </p>
                <hr />

                <h2>Frage 1: Ist die Beratung unabhängig?</h2>
                <p>
                    Bei deiner{" "}
                    <Link to="/artikel/baufinanzierung/" target="_blank" rel="noreferrer noopener">
                        Baufinanzierung
                    </Link>{" "}
                    geht es vor allem um eines: die für deine individuelle Situation beste Option zu finden. Aus genau
                    diesem Grund holst du dir schließlich eine Beratung. Wenn dein Berater oder deine Beraterin nur für
                    eine Bank arbeitet, ist sie dadurch zwar nicht automatisch schlecht, allerdings schränkt das deine
                    Möglichkeit enorm ein, die beste Option zu finden. Diese könnte schließlich auch eine andere Bank
                    bieten, die deine Finanzberatung überhaupt nicht in Betracht zieht. Deshalb solltest du dich immer
                    fragen: Gibt es Kooperationen mit anderen Banken?
                </p>
                <p>
                    Einen kompetenten Baufinanzierungsberater erkennst du zudem daran, dass er einerseits eine Auswahl
                    aus allen Finanzierungsmodellen trifft, die sich in deinem Fall anbieten, sodass du bei deiner
                    Entscheidung auf einen überschaubaren Pool an Möglichkeiten zurückgreifen und dich selbst eingehend
                    mit den einzelnen Angeboten befassen kannst. Andererseits sollte dein Berater natürlich möglichst
                    viele Angebote vergleichen, bevor er dir diese kondensierte Liste vorlegt. Im Fall von Banken ist
                    dies allerdings kaum umsetzbar, weil diese daran interessiert sind, dir die entsprechende{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditsumme
                    </Link>{" "}
                    selbst zur Verfügung zu stellen. Ganz anders sieht es bei Kreditvermittlungsunternehmen wie miracl
                    aus. Weil diese von den jeweiligen Kreditinstituten völlig unabhängig sind – bei Vertragsabschluss
                    von diesen also keine Prämie erhalten –, können sie dir einen objektiveren Überblick über sämtliche
                    Angebote verschaffen, die für dich in Frage kommen.
                </p>
                <p>
                    Wichtig ist, dass deine Finanzierungsberatung keine Provisionen von Produktanbietern erhält, sondern
                    ihr Honorar ausschließlich durch dich – ihren Auftraggeber – erhalten. Das sorgt nämlich dafür, dass
                    du dir sicher sein kannst, dass wirklich in deinem Interesse gehandelt wird.
                </p>
                <hr />

                <h2>Frage 2: Ist die Beratung transparent?</h2>
                <p>
                    Diese Frage hängt stark mit der ersten Frage zusammen. Denn wenn die Beratung nicht transparent
                    arbeitet, liegt der Verdacht nahe, dass sie nicht völlig in deinem Interesse handelt. Deine
                    Baufinanzierungsberater sollten stets offenlegen, wie sie arbeiten und welche Faktoren sie dabei
                    beachten. Wenn du Fragen hast, sollten sie dir darauf Antworten geben können. Auch über die
                    Bezahlung musst du bereits im vorhinein informiert werden. Natürlich müssen und können sie dir nicht
                    jede Kleinigkeit über ihren Arbeitsprozess beantworten, aber wenn dir wichtige Dinge vorenthalten
                    werden, solltest du misstrauisch werden.
                </p>
                <p>
                    Ein gutes Indiz dafür, dass transparent gearbeitet wird, ist, wenn du im Vorfeld und ohne
                    nachzufragen über den Arbeitsvorgang informiert wirst und das Gefühl hast, grundsätzlich bei allem
                    nachfragen zu dürfen.
                </p>
                <hr />

                <h2>Frage 3: Hat der Berater die nötige Erfahrung?</h2>
                <p>
                    Das Thema Erfahrung ist vor allem in einer Hinsicht relevant: es geht dabei um die Kompetenz. Wenn
                    man sich über das Thema{" "}
                    <Link to="/artikel/baufinanzierung/" target="_blank" rel="noreferrer noopener">
                        Baufinanzierung
                    </Link>{" "}
                    beraten lässt, tut man das, um sich den Rat einer Person einzuholen, die in diesem Bereich durch
                    Erfahrung und angeeignetes Wissen kompetenter ist als man selbst. Diese Kompetenz soll dir dabei
                    helfen, die beste Entscheidung zu treffen. Einen kompetenten Berater oder eine kompetente Beraterin
                    erkennst du daran, wenn sie schon einige Jahre in diesem Bereich tätig ist, diesen Bereich lange
                    studiert haben und/oder auf Referenzen verweisen können. Außerdem sollten sie dich bei sämtlichen
                    Schritten deiner{" "}
                    <Link to="/artikel/immobilienfinanzierung/" target="_blank" rel="noreferrer noopener">
                        Immobilienfinanzierung
                    </Link>{" "}
                    begleiten. Das beginnt bei der Ermittlung einer für dich problemlos leistbaren{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditsumme
                    </Link>
                    , die dich nicht in die Schuldenfalle treibt. Hierfür wird dich die Beratung bitten, eine
                    Haushaltsrechnung zu erstellen. Grundsätzlich ist das Gefühl, dass Interesse an deiner individuellen
                    Situation besteht und dass diese auch richtig eingeschätzt werden kann, das beste Indiz dafür, ob du
                    deine Finanzierung mit ihrer Hilfe abwickeln solltest.
                </p>
                <hr />

                <h2>Frage 4: Ist mir der Berater sympathisch?</h2>
                <p>
                    Und das führt zu einer nicht weniger wichtigen Frage, die du dir stellen solltest: findest du den
                    Baufinanzierungsberater sympathisch? Denn auch das ist wichtig. Eine gute Beratung begleitet dich
                    verlässlich durch den gesamten Prozess und engagiert sich dafür, dass du am Ende mit der besten
                    Finanzierung dein Bauvorhaben starten kannst. Dieser Prozess kann allerdings Wochen oder auch Monate
                    dauern. Nur wenn ihr einander halbwegs sympathisch seid, könnt ihr diese Zeit gemeinsam an der
                    besten Lösung für dich arbeiten. Nur wenn du dich gut betreut fühlst, macht die Beratung Sinn. Denn
                    nur so kannst du nachfragen, lernen, gemeinsam die Optionen diskutieren und am Ende das Gefühl
                    haben, dass man dich hier wirklich unterstützt hat.
                </p>
                <hr />

                <h2>Frage 5: Warum nicht miracl?</h2>
                <p>
                    Eines ist sicher: Diese Frage kann selbst der kompetenteste Berater nicht beantworten. Wir von
                    miracl vergleichen nicht nur die Angebote von über 60 Banken, von denen wir zu 100 % unabhängig
                    sind. Wir sind auch transparent und haben ein engagiertes erfahrenes Team. Wenn du dich für miracl
                    entscheidest, entscheidest du dich außerdem dafür, auf unnötige Wegzeiten zu verzichten. Mit unseren
                    Beratern und Beraterinnen kommst du direkt zur Sache. Für dich die ideale Finanzierung zu finden ist
                    unser Hauptziel, denn wir leben von unseren Kunden und Kundinnen und nicht von den Provisionen von
                    Banken. Mit uns findest du bald dein neues Zuhause, ohne dass du dein altes dabei verlassen musst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Kreditvergleich anfordern!
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"baufinanzierungsberater"}></BreadcrumbList>
            <ArticleStructuredData
                page={"baufinanzierungsberater"}
                heading={"An 5 Fragen den idealen Baufinanzierungsberater erkennen"}
            />
        </Layout>
    );
};

export default Baufinanzierungsberater;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.baufinanzierungsberater", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
